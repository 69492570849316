import eventBus from '@/eventBus'
import axios from '../../axios'

const onError = e => {
	console.error(e)
	eventBus.$emit('flashMessage', {
		message: "Oops, we weren't able to use that login. Please try again",
	})
}

export const initiateSso = type => {
	return new Promise((resolve, reject) => {
		axios
			.post('user/sso-state-uuid', {
				name: null, // will take returned value from sso provider
				source: 'account',
				sourceUri: location.origin,
			})
			.then(({ data }) => {
				window.open(
					`${process.env.VUE_APP_MARKETING_URI}sso/${type}?state=${data.id}`,
					'momo-extension-sso-login',
					'popup'
				)

				window.addEventListener('message', message => {
					if (message.data && 'ssoResponseCallback' in message.data) {
						if ('otp' in message.data.ssoResponseCallback) {
							resolve(message.data.ssoResponseCallback)
						} else {
							reject('Could not login using sso. no OTL parameters received')
						}
					}
				})
			}, reject)
			.catch(onError)
	})
}
