<template>
	<smooth-height>
		<div v-if="showTypes && adding" key="1" class="metric-section">
			<h2 v-if="adding && !inOnboarding">What type of metric would you like to add?</h2>
			<ul class="types">
				<li
					v-for="(type, key) in $options.metricTypes"
					:key="key"
					:class="type.class"
					data-test="metric-type"
					class="modal-tile type"
					@click="selectType(type)"
				>
					<span class="type-label">{{ type.label }}</span>
					<span class="type-description">{{ type.description }}</span>
				</li>
			</ul>
		</div>
		<div v-else key="2" class="metric-section">
			<h3 class="box-header">
				<span v-if="adding" class="icon-wrapper go-back" data-test="back-to-types" @click="handleBack">
					<svg class="icon icon-back" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.04 492"><path d="M198.61,246.1,382.66,62a27,27,0,0,0,0-38.06L366.54,7.86a27,27,0,0,0-38.06,0L109.33,227a27.15,27.15,0,0,0,0,38.19L328.27,484.14a27,27,0,0,0,38.07,0L382.46,468a27,27,0,0,0,0-38.06Z" transform="translate(-101.48)" /></svg>
				</span>
				<transition name="fade" mode="out-in" duration="200">
					<div :key="`${typeHeader}:${providerIconSrc}`" class="header-content">
						<img v-if="providerIconSrc" :src="providerIconSrc">
						<span>{{ typeHeader }}</span>
					</div>
				</transition>
			</h3>
			<edit-prop
				v-for="(prop, index) in renderableProps"
				ref="editProp"
				:key="index"
				v-model="editProps[prop.name]"
				:adding="adding"
				:manager="manager"
				:prop="prop"
				:edit-props="editProps"
				:selected-item="selectedItem"
				:item-name="itemName"
				:highlight-required-component-fields="highlightRequiredComponentFields"
			/>
		</div>
	</smooth-height>
</template>



<script>
import EditProp from '@/components/common/EditProp'
import SmoothHeight from '@/components/common/SmoothHeight'
import { metricTypes } from '@/constants'
import teamMetricManager from '../../../managers/teamMetricManager'

export default {
	name: 'MetricEdit',
	metricTypes: [
		// Ensure Order matches metricTypes ENUM
		{
			id: metricTypes.MANUAL,
			label: 'Manual',
			description: 'Track Manually',
			heading: 'Manual',
		},
		{
			id: metricTypes.URL,
			label: 'From a URL',
			description: 'Update from a URL',
			heading: 'URL',
		},
		{
			id: metricTypes.INTEGRATION,
			label: 'From an Integration',
			description: 'Connect to a service',
			heading: 'Integration',
		},
	],
	components: { SmoothHeight, EditProp },
	props: {
		manager: { type: Object, required: true },
		editProps: { type: Object, required: true },
		itemName: { type: String, default: '' },
		selectedItem: { type: Object, required: true },
		highlightRequiredComponentFields: { type: Array, default: () => [] },
		adding: { type: Boolean, default: false },
		inOnboarding: { type: Boolean, default: false },
	},
	data() {
		return {
			showTypes: true,
			activeType: '',
			paths: {},
		}
	},
	computed: {
		providers() {
			return teamMetricManager.providers
		},
		typeHeader() {
			if (this.hasProvider && this.providers.loaded) return this.providers.items[this.editProps.providerId]?.title
			const typeInfo = this.$options.metricTypes[this.editProps.type]
			return typeInfo && typeInfo.heading
		},
		hasProvider() {
			const providerId = this.editProps.providerId
			return this.editProps.type === metricTypes.INTEGRATION && (providerId || providerId === 0)
		},
		providerIconSrc() {
			if (this.hasProvider && this.providers.loaded) {
				return this.providers.items[this.editProps.providerId]?.smallIconUrl
			}
		},
		renderableProps() {
			return this.manager.addInfo.props.filter(prop => this.shouldRenderProp(prop, this.editProps))
		},
	},
	methods: {
		selectType(type) {
			this.showTypes = false
			this.activeType = type
			this.$set(this.editProps, 'type', type.id)
			this.$nextTick(this.focusInput)
		},
		handleBack() {
			if (this.hasProvider) this.resetProvider()
			else this.backToTypes()
		},
		resetProvider() {
			this.$set(this.editProps, 'providerId', null)
			this.$set(this.editProps, 'providerDataPointId', '')
			this.$set(this.editProps, 'label', '')
		},
		backToTypes() {
			this.showTypes = true
			this.activeType = ''
			this.$set(this.editProps, 'type', null)
			this.clearWarnings()
		},
		clearWarnings() {
			this.manager.addInfo.props.forEach(prop => (prop.warning = false))
		},
		isHiddenInList(prop, model) {
			// Don't render if prop's hiddenInList is true or if model's prop has no value unless it of type: custom. if prop's alwaysShowInList is true, always render
			return (
				(this.callOrUse('hiddenInList', prop, model) || (!model[prop.name] && prop.type !== 'custom')) &&
				!prop.alwaysShowInList
			)
		},
		isHiddenInForm(prop, model) {
			return this.callOrUse('hiddenInForm', prop, model)
		},
		shouldRenderProp(prop, model) {
			if (prop.type === 'hidden') return false
			if (prop.name === 'metricEdit') return false
			if (this.isHiddenInForm(prop, model) && !this.callOrUse('showInMetricEdit', prop, model)) return false
			if (this.adding) {
				return !prop.editingOnly
			} else {
				return !prop.addingOnly
			}
		},
		callOrUse(attribute, prop, model) {
			// If the prop attribute is a function, call it, or use as is if not.
			return typeof prop[attribute] === 'function' ? prop[attribute](model) : prop[attribute]
		},
		focusInput() {
			if (this.$refs.editProp && this.$refs.editProp[0] && this.$refs.editProp[0].focus) {
				this.$refs.editProp[0].focus()
			}
		},
	},
}
</script>



<style scoped>

	.transition-active .u--resize-wrapper { height: auto !important; transition: none !important; }
	.u--resize-wrapper { margin-bottom: var(--form-bottom-margin); }

	.types { margin: 0 0 var(--form-bottom-margin); display: grid; grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); grid-gap: 1rem; }
		.type-label { margin-bottom: 5px; font-weight: 700; }
		.type-description { opacity: 0.7; }
	h2, h3 { margin-top: 0; }
		.box-header .header-content { display: inline-flex; align-items: center; }
			.box-header .header-content img { height: 25px; margin-right: 0.5rem; display: block; }
	.metric-section { display: flex; flex-direction: column; }

	@media handheld and (max-width: 960px), screen and (max-device-width: 960px), screen and (max-width: 960px) {
		.types { grid-template-columns: repeat(auto-fit, 100%); }
	}

</style>
