import Vue from 'vue'
import { errorMessages, salesPreview } from '@/constants'
import subscriptionDataService from '../services/subscriptionDataService'
import util from '@/util'
import store from '@/store'
import extensionManager from '../managers/extensionManager'

export default new (Vue.extend({
	computed: {
		currentSubscription() {
			return store.subscriptionInfo.currentSubscription
		},
		isTrialingPlus() {
			return store.userInfo.subscriptionSummary.trialActive && !store.userInfo.isTeamMember
		},
		isTrialingTeam() {
			return store.teamInfo.activeTeam.trialActive
		},
		trialEnd() {
			return store.userInfo.subscriptionSummary.trialEnd
		},
	},
	methods: {
		getSubscriptions(silent) {
			if (!silent) {
				Vue.set(store.subscriptionInfo, 'loaded', false)
				Vue.set(store.subscriptionInfo, 'error', false)
			}
			return new Promise((resolve, reject) => {
				if (!store.authInfo.loggedIn) reject()
				subscriptionDataService
					.getSubscriptions()
					.then(subscriptionInfo => {
						store.subscriptionInfo.update(subscriptionInfo)
						if (!silent) Vue.set(store.subscriptionInfo, 'loaded', true)
						resolve(subscriptionInfo)
					})
					.catch(error => {
						console.log(error)
						if (silent) return
						Vue.set(store.subscriptionInfo, 'loaded', true)
						Vue.set(store.subscriptionInfo, 'error', true)
						reject(error)
					})
			})
		},
		subscribe(stripeToken, plan) {
			return new Promise((resolve, reject) => {
				subscriptionDataService
					.subscribe(stripeToken, plan)
					.then(response => {
						Vue.set(store.userInfo.subscriptionSummary, 'active', true)
						resolve(response.data)
					})
					.catch(error => {
						let errorMessage = errorMessages.SERVER_ERROR_GENERAL
						if (error.response) {
							if (!error.response.success && error.response.message) {
								errorMessage = `There was an error processing your subscription request. The message from your bank or the payment provider was: &quot; ${error.response.message} &quot;.`
							}
						}
						reject(errorMessage)
					})
			})
		},
		subscribeWithEmail(stripeToken, plan, action, email, name, sale, recaptchaResult, mailingLists) {
			const planId = (sale && sale.id) || (plan && plan.id)
			const extensionVersion = extensionManager.checkIfExtensionIsInstalled()
			const couponId = sale && sale.coupon
			return new Promise((resolve, reject) => {
				subscriptionDataService
					.subscribeWithEmail(
						stripeToken,
						planId,
						action,
						email,
						name,
						recaptchaResult,
						extensionVersion,
						couponId,
						mailingLists
					)
					.then(response => {
						// 'isExistingUser' is used specifically to show correct message in Thanks page after user upgrades
						localStorage.setItem('isExistingUser', response.isExistingUser)
						localStorage.setItem('otp', response.oneTimePassword)
						if (store.authInfo.loggedIn) {
							Vue.set(store.userInfo.subscriptionSummary, 'active', true)
						} else {
							const currentPeriodEnd = this.nextPeriodEnd(plan.interval)
							Vue.set(store.subscriptionInfo, 'currentSubscription', {
								plan,
								currentPeriodEnd,
								sale,
							})
							if (response.userUuid) {
								localStorage.setItem('user-uuid', response.userUuid)
							}
						}
						resolve(response)
					})
					.catch(error => {
						const result = Object.assign({}, error.response.data)
						result.message = result.message
							? `There was an error processing your payment. ${result.message}`
							: errorMessages.SERVER_ERROR_GENERAL
						reject(result)
					})
			})
		},
		confirmSubscription(mailingLists) {
			return subscriptionDataService.confirmSubscription(mailingLists)
		},
		nextPeriodEnd(planInterval) {
			return util.dateAdd(new Date(), planInterval, 1)
		},
		switchPlan() {
			const plan = this.currentSubscription.plan
			const plusTrialEnded = !store.userInfo.isAdmin && this.trialEnd && new Date() > new Date(this.trialEnd)

			return new Promise((resolve, reject) => {
				subscriptionDataService
					.switchPlanTo(this.currentSubscription.id, plan.otherId)
					.then(response => {
						let output = {}
						let newCharge = '$' + util.formatNumber(response.chargeAmount)
						let refund = '$' + util.formatNumber(response.refundAmount)
						let amountDue = '$' + util.formatNumber(response.amountDue)

						// Update renewal date
						let nextRenewDate = new Date()
						if (plan.otherTerm === 'monthly' || this.isTrialingPlus) {
							nextRenewDate.setMonth(nextRenewDate.getMonth() + 1)
						} else {
							nextRenewDate.setFullYear(nextRenewDate.getFullYear() + 1)
						}

						if (this.isTrialingTeam) {
							output.summaryTop = ''
							output.summaryBottom = `Don’t worry, you won’t be charged until your trial ends.`
						} else if (this.isTrialingPlus) {
							// TODO: make the 27.97 come from api under a variable like OtherPlanPrice
							output.summaryTop = `<strong>Save ${this.currentSubscription.plan.switchPlanPercentOff}% annually</strong> by switching to the <strong>$27.97/year</strong> plan!`
							output.summaryBottom = `Your card won't be charged until your trial ends on <strong>${util.formatDate(
								this.currentSubscription.trialEnd
							)}</strong>.`
						} else if (plusTrialEnded) {
							// same issue as TODO above
							output.summaryTop = `<strong>Save ${this.currentSubscription.plan.switchPlanPercentOff}% annually</strong> by switching to the <strong>$27.97/year</strong> plan!`
						} else {
							// Generate top message
							output.summaryTop = ''
							if (response.refundAmount > 0) {
								output.summaryTop =
									'You will receive a <strong>' +
									refund +
									' credit</strong> on your account for the remaining time on your ' +
									this.currentSubscription.plan.frequency.toLowerCase() +
									' plan.'
							}

							// Generate bottom message
							if (response.amountDue === 0) {
								output.summaryBottom = `Your card won’t be charged today.`
							} else if (response.amountDue > 0 && response.amountDue < 1) {
								output.summaryBottom = `Your card will be charged <strong>${amountDue}</strong> today<sup>*</sup>.`
							} else {
								output.summaryBottom = `Your card will be charged <strong>${amountDue}</strong> today.`
							}
							output.summaryBottom += ` Your subscription will renew on <strong>${util.formatDate(
								nextRenewDate
							)}</strong> for <strong>${newCharge}</strong>.`
						}
						resolve(output)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		cancel(feedback) {
			return new Promise((resolve, reject) => {
				subscriptionDataService
					.cancel({ feedback: feedback, subscription_id: this.currentSubscription.id })
					.then(response => {
						this.getSubscriptions(false)
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		resume() {
			return new Promise((resolve, reject) => {
				subscriptionDataService
					.resume(this.currentSubscription)
					.then(response => {
						this.getSubscriptions(false)
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		renewSubscription(planId) {
			return new Promise((resolve, reject) => {
				subscriptionDataService
					.renewSubscription(planId)
					.then(response => {
						this.getSubscriptions(true)
						resolve(response)
					})
					.catch(error => {
						let message = null
						if (!error.response.success && error.response.message) {
							message =
								'The message from your bank or the payment provider was: "' +
								error.response.message +
								'"'
						}
						Vue.set(store.subscriptionInfo, 'bankMessage', message)
						reject(message)
					})
			})
		},
		retryLastChargeOnPrimaryCard(subscriptionId) {
			return subscriptionDataService.retryLastChargeOnPrimaryCard(subscriptionId)
		},
		subscribeFromTrial(subscriptionId, requestBody) {
			return subscriptionDataService.subscribeFromTrial(subscriptionId, requestBody)
		},
		resubscribe(stripeToken, name, recaptchaToken) {
			return subscriptionDataService.resubscribe(stripeToken, name, recaptchaToken)
		},
		confirmSwitch() {
			Vue.set(store.subscriptionInfo, 'bankMessage', null)
			return new Promise((resolve, reject) => {
				subscriptionDataService
					.confirmSwitch(this.currentSubscription)
					.then(response => {
						this.getSubscriptions(false)
						resolve(response)
					})
					.catch(error => {
						let message = null
						if (error && error.response) {
							message = error.response.message
						}
						Vue.set(store.subscriptionInfo, 'bankMessage', message)
						reject(message)
					})
			})
		},
		getGifts() {
			subscriptionDataService
				.getGifts()
				.then(coupons => {
					if (coupons.length > 0) {
						coupons.forEach(coupon => {
							coupon.friendlyDate = util.formatDate(coupon.created_date)
							if (!coupon.redeemed_date) {
								store.giftInfo.unredeemedCoupons = true
							}
						})
						Vue.set(store.giftInfo, 'coupons', coupons)
					}
					Vue.set(store.giftInfo, 'loaded', true)
					Vue.set(store.giftInfo, 'error', false)
				})
				.catch(() => {
					Vue.set(store.giftInfo, 'loaded', true)
					Vue.set(store.giftInfo, 'error', true)
				})
		},
		getPlans(coupon) {
			let promiseArray = [subscriptionDataService.getPlans()]
			if (coupon) promiseArray.push(subscriptionDataService.getCoupon(coupon))
			if (store.userInfo.isTeamMember) promiseArray.push(this.getSubscriptions())
			return Promise.allSettled(promiseArray).then(([plans, coupons]) => {
				const isDev = new URLSearchParams(window.location.search).has('dev')
				const devParams = localStorage.getObject('devParams')
				if (plans.status === 'fulfilled') {
					Object.values(plans.value).forEach(planGroup => {
						// Ensures if there are no sales, component's props will fall back to defaults.
						// TODO: when we have back-end devs again, change the response so that if there are no sales, rather than sales being null, it should just not exist
						if (planGroup.hasOwnProperty('sales') && planGroup.sales === null) {
							delete planGroup.sales
						}
					})
				}
				if (coupon && coupons && coupons.status === 'fulfilled') {
					if (!coupons.value.name) coupons.value.name = 'Coupon'
					this.adjustPlansFromCoupon(plans.value.personal, coupons.value)
				} else if (
					store.userInfo.isTeamMember &&
					this.currentSubscription?.stripeSubscription?.discount?.coupon
				) {
					const coupon = this.currentSubscription?.stripeSubscription?.discount?.coupon
					if (coupon.valid) {
						this.adjustPlansFromCoupon(plans.value.team, coupon)
					}
				} else if (isDev && devParams && devParams.salesPreview) {
					plans.value.personal.sales = salesPreview
				}
				Vue.set(store, 'planInfo', Object.assign(plans.value ?? {}, { loaded: true }))
			})
		},
		adjustPlansFromCoupon(planInfo, couponInfo) {
			planInfo.sales = {}
			Object.entries(planInfo.plans).forEach(([frequency, plan]) => {
				planInfo.sales[frequency] = {
					id: plan.id,
					coupon: couponInfo.id,
					interval: plan.interval,
					title: couponInfo.name || 'Coupon',
					percentOff: couponInfo.percent_off,
					badgeText: couponInfo.percent_off + '% Off',
					friendlyPrice: (plan.friendlyPrice * (1 - couponInfo.percent_off / 100)).toFixed(2),
					friendlyMonthlyPrice: plan.friendlyMonthlyPrice
						? (plan.friendlyMonthlyPrice * (1 - couponInfo.percent_off / 100)).toFixed(2)
						: null,
				}
			})
		},
	},
}))()
