import Vue from 'vue'

export default new (Vue.extend({
	methods: {
		getUser() {
			return new Promise((resolve, reject) => {
				this.$http
					.get('user/me')
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		getUserDetails() {
			return this.$http.get('user/profile?details=1').then(response => response.data)
		},

		updateProfile(profile) {
			return new Promise((resolve, reject) => {
				this.$http
					.patch('user/profile', profile)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		deleteProfile() {
			return new Promise((resolve, reject) => {
				this.$http
					.delete('user', this.credentials)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		setEmailAsDefault(emailId) {
			return new Promise((resolve, reject) => {
				this.$http
					.patch('user/profile/emails/' + emailId, { operation: 'set_default' })
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		sendVerification(emailId) {
			return new Promise((resolve, reject) => {
				this.$http
					.patch('user/profile/emails/' + emailId, { operation: 'resend_verification' })
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		addEmail(newEmail) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('user/profile/emails/', { email: newEmail })
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		oneTimeLogin(medium) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('login/onetime', { medium: medium })
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		deleteEmail(emailId) {
			return new Promise((resolve, reject) => {
				this.$http
					.delete('user/profile/emails/' + emailId)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		hardDelete() {
			return new Promise((resolve, reject) => {
				this.$http
					.delete('user/hard/')
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},
}))()
