import Vue from 'vue'
import { authMedium, copy, errorMessages, flashMessages, minimumPasswordLength } from '@/constants'
import store from '@/store'
import authDataService from '@/services/authDataService'
import currentUserManager from '@/managers/currentUserManager'
import teamManager from '@/managers/teamManager'
import eventBus from '@/eventBus'
import util from '@/util'
import router from '@/router'
import Analytics from '../Analytics'
const analytics = new Analytics({ feature: 'login' })

export default {
	initialize() {
		if (localStorage.getItem('token')) {
			this.saveLoginInfo()
		} else if (window.location.search && window.location.search.indexOf('state=') >= 0) {
			localStorage.setItem('loginParams', window.location.search)
			router.push('/team-redirect')
		} else {
			this.clearAuthState()
		}
	},
	login(credentials) {
		store.authInfo.inProgress = true
		authDataService
			.login(credentials)
			.then(response => {
				Vue.set(store.interfaceState, 'blockBanner', true)
				this.saveLoginInfo(response).then(() => router.go())
				analytics.identify(response.user_uuid)
				analytics.capture('login-by-password succeed')
			})
			.catch(error => {
				console.error(error)
				store.authInfo.inProgress = false
				let status = error.response ? error.response.status : null
				if (status === 400 || status === 401 || status === 403) {
					store.authInfo.errorMessage = errorMessages.EMAIL_OR_PASSWORD_INCORRECT
				} else if (status === 429) {
					store.authInfo.errorMessage = errorMessages.TOO_MANY_LOGIN_ATTEMPTS
				} else {
					store.authInfo.errorMessage = errorMessages.SERVER_ERROR_GENERAL
				}
			})
	},
	processPendingLogin() {
		let pendingLoginState = localStorage.getItem('pendingLoginState')
		if (pendingLoginState) {
			authDataService
				.login({ pendingState: pendingLoginState })
				.then(msg => {
					if (msg.token) {
						this.saveLoginInfo(msg).then(() => {
							localStorage.removeItem('pendingLoginState')

							let loginParams = localStorage.getItem('loginParams'),
								urlParams = new URLSearchParams(loginParams),
								redirect = util.getRelativeUrl(urlParams.get('redirect'))

							if (redirect) {
								localStorage.removeItem('redirectTo')
								router.push(redirect)
							} else {
								router.push('/')
							}
							localStorage.removeItem('loginParams')
						})
					}
				})
				.catch(() => {
					localStorage.removeItem('pendingLoginState')
					localStorage.removeItem('loginParams')
					router.push('/team-login')
				})
		} else {
			router.push('/team-login')
		}
	},
	checkForThirdParty(email, startLogin) {
		store.authInfo.errorMessage = null

		return new Promise(resolve => {
			if (startLogin) store.authInfo.inProgress = true
			if (this.brandingInfo && this.brandingInfo.email === email) {
				if (startLogin) this.loginToThirdParty(email)
				return
			}
			authDataService
				.checkForThirdParty(email)
				.then(msg => {
					if (msg && msg.managedDomain) {
						this.brandingInfo = msg.brandingInfo
						this.brandingInfo.email = email
						if (startLogin) {
							let loginMessage = this.loginToThirdParty(email)
							resolve({ needPassword: false, brandedLogin: loginMessage })
							return
						}
						store.authInfo.inProgress = false
						resolve({
							needPassword: false,
							brandedLogin: 'Log in with ' + msg.brandingInfo.idProviderName,
						})
					} else {
						store.authInfo.inProgress = false
						resolve({ needPassword: true })
					}
				})
				.catch(error => {
					this.brandingInfo = null
					store.authInfo.inProgress = false
					if (startLogin) {
						let status = error.response ? error.response.status : null
						if (status === 400 || status === 401 || status === 403) {
							store.authInfo.errorMessage = errorMessages.EMAIL_OR_PASSWORD_INCORRECT
						}
						resolve({ needPassword: true })
					} else {
						resolve({ needPassword: true })
					}
				})
		})
	},
	loginToThirdParty(email) {
		const brandingInfoBase = { messageTemplate: `Redirecting to {{idProviderName}}…` }
		let brandingInfo = Object.assign({}, brandingInfoBase, this.brandingInfo)
		this.brandingInfo = null // Reset

		store.authInfo.loginLabelOverride =
			brandingInfo.message ||
			brandingInfo.messageTemplate.replace('{{idProviderName}}', brandingInfo.idProviderName)
		let medium = 'account'
		let mediumURL = window.location.href

		authDataService
			.loginSSO({
				email: email,
				medium: medium,
				mediumURL: mediumURL,
			})
			.then(function (msg) {
				if (msg) {
					localStorage.setItem('pendingLoginState', JSON.stringify(msg))
					if (msg.redirectUrl) window.location.href = msg.redirectUrl
				}
			})

		return store.authInfo.loginLabelOverride
	},
	saveLoginInfo(response) {
		if (response) {
			if (response.token) window.localStorage.setItem('token', response.token)
			if (response.team_member) {
				window.localStorage.setItem('teamMember', response.team_member)
				store.userInfo.isTeamMember = true
			} else {
				window.localStorage.removeItem('teamMember')
			}
			if (response.token_uuid) {
				window.localStorage.setItem('token_uuid', response.token_uuid)
			}
		}
		store.authInfo.setLoginState(true)
		return new Promise(resolve => {
			currentUserManager.getProfile().then(() => {
				if (store.userInfo.isTeamMember || store.userInfo.isCommunityMember) teamManager.refreshTeams()
				resolve()
			})
		})
	},
	register(info) {
		if (info.name.trim().length === 0) {
			store.authInfo.errorMessage = errorMessages.NAME_BLANK
			return
		}
		if (!this.checkEmail(info) || !this.checkPassword(info)) return
		store.authInfo.inProgress = true
		authDataService
			.register(info)
			.then(response => {
				if (response.token) {
					const userInfo = {
						email: info.email,
						full_name: info.name,
						display_name: response.display_name,
					}
					window.localStorage.setItem('token', response.token)
					if (response.token_uuid) window.localStorage.setItem('token_uuid', response.token_uuid)
					store.authInfo.setLoginState(true)
					store.userInfo.update(userInfo)
					analytics.capture('registration succeed')
					if (info.communityUuid) router.push({ name: 'onboarding-finished' })
					else router.go()
				}
			})
			.catch(error => {
				console.log(error)
				store.authInfo.inProgress = false
				store.authInfo.errorMessage = errorMessages.SERVER_ERROR_GENERAL
			})
	},
	logout() {
		this.clearAuthState()
		window.localStorage.clear()
		teamManager.resetTeamData() // Clears store of all team data
		store.authInfo.inProgress = false
		store.authInfo.setLoginState(false)
		store.userInfo.resetUserData()
		store.subscriptionInfo.resetSubscriptionData()
		store.giftInfo.resetGiftData()
		analytics.reset()
		Vue.set(store.interfaceState, 'blockBanner', true)
		eventBus.$emit('flashMessage', flashMessages.LOGGED_OUT)
	},
	clearAuthState() {
		window.postMessage(
			{
				type: 'momentum:authState',
				data: { src: 'account', loginState: false },
			},
			window.location
		)
	},
	createPassword(info, router) {
		this.clearMessages()
		store.authInfo.inProgress = true
		if (!this.checkPassword(info)) {
			store.authInfo.inProgress = false
			return
		}
		authDataService
			.createPassword(info)
			.then(response => {
				store.authInfo.inProgress = false
				window.localStorage.setItem('token', response.token)
				if (response.token_uuid) {
					window.localStorage.setItem('token_uuid', response.token_uuid)
				}
				this.saveLoginInfo(response)
				if (!store.teamInfo.activeTeam.onboardingComplete) {
					router.push({ path: 'onboarding', query: { redirect: '/team/onboarding' } })
				}
			})
			.catch(() => {
				store.authInfo.inProgress = false
				store.authInfo.error = true
				store.authInfo.errorMessage = errorMessages.SERVER_ERROR_GENERAL
			})
	},
	checkEmail(info) {
		this.clearMessages()
		if (!info.email) {
			store.authInfo.errorMessage = errorMessages.EMAIL_BLANK
			return false
		}
		if (!util.isEmail(info.email)) {
			store.authInfo.errorMessage = errorMessages.EMAIL_INVALID
			return false
		}
		return true
	},
	checkPassword(info) {
		this.clearMessages()
		if (typeof info.verify === 'string' && info.password !== info.verify) {
			store.authInfo.errorMessage = errorMessages.PASSWORD_MISMATCH
			return false
		}
		if (info.password.length < minimumPasswordLength) {
			store.authInfo.errorMessage = errorMessages.PASSWORD_TOO_SHORT
			return false
		}
		return true
	},
	checkEmailAndPassword(info) {
		this.clearMessages()
		this.info = info
		if (store.authInfo.loggedIn) {
			this.info.name = store.userInfo.display_name
			this.info.email = store.userInfo.email
		}
		return this.checkEmail(this.info) && this.checkPassword(this.info)
	},
	setPassword(info) {
		store.authInfo.inProgress = true
		return new Promise((resolve, reject) => {
			authDataService
				.setPassword(info)
				.then(response => {
					store.authInfo.inProgress = false
					store.authInfo.statusMessage = copy.RESET_PASSWORD_STARTED
					resolve(response)
				})
				.catch(error => {
					store.authInfo.inProgress = false
					store.authInfo.errorMessage = errorMessages.SERVER_ERROR_GENERAL
					reject(error)
				})
		})
	},
	setPasswordForNewPlusUser(info) {
		store.authInfo.inProgress = true
		return new Promise((resolve, reject) => {
			authDataService
				.setPasswordForNewPlusUser(info)
				.then(res => {
					const userId = localStorage.getItem('user-uuid')
					if (userId) localStorage.setItem(`password-set-for-${userId}`, true)
					localStorage.setItem('token', res.token)
					localStorage.setItem('token_uuid', res.token_uuid)
					store.authInfo.inProgress = false
					store.authInfo.statusMessage = copy.RESET_PASSWORD_STARTED
					resolve(res)
				})
				.catch(error => {
					store.authInfo.inProgress = false
					store.authInfo.errorMessage = errorMessages.SERVER_ERROR_GENERAL
					reject(error)
				})
		})
	},
	sendEmailLink(info) {
		this.info = info
		info.medium = authMedium
		store.authInfo.inProgress = true
		return new Promise((resolve, reject) => {
			authDataService
				.sendEmailLink(this.info)
				.then(response => {
					store.authInfo.inProgress = false
					store.authInfo.statusMessage = copy.ONETIME_LOGIN_STARTED
					resolve(response)
				})
				.catch(error => {
					store.authInfo.inProgress = false
					store.authInfo.errorMessage = errorMessages.SERVER_ERROR_SEND_EMAIL_LINK
					reject(error)
				})
		})
	},
	handleOneTime(route) {
		let info = { otp: '', redirect: '/' }
		if (route.query.email) info.email = route.query.email
		if (route.query.uuid) info.uuid = route.query.uuid
		info.otp = route.query.otp
		info.medium = 'account'
		let redirect = route.query.redirect || '/'
		if (redirect !== '/') info.redirect = decodeURIComponent(redirect)
		// TODO When re-connecting the onetime login, check if this `redirect` variable is still needed
		this.redeemOnetimeToken(
			info,
			msg => {
				this.redeemSuccess(msg, router, redirect)
			},
			err => {
				this.redeemFail(err, route, router)
			}
		)
	},
	redeemOnetimeToken(info, success, error) {
		new Vue().$http
			.post('login/onetime/redeem', info)
			.then(response => {
				let msg = response.data
				success(msg)
			})
			.catch(() => {
				error()
			})
	},
	redeemSuccess(msg, router, redirect) {
		if (msg.token) {
			this.saveLoginInfo(msg).then(() => {
				router.push(redirect || '/').catch(() => {}) // suppress further redirects errors
			})
		}
	},
	redeemFail(err, route, router) {
		router.push({
			path: 'link-expired',
			query: { email: route.query.email, redirect: route.query.redirect },
		})
	},
	clearMessages() {
		store.authInfo.errorMessage = null
		store.authInfo.statusMessage = null
	},
}
