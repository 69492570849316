export const linkTypes = { DEPRECATED: 0, GROUP: 1, SUBLINK: 2 }
export const metricTypes = { MANUAL: 0, URL: 1, INTEGRATION: 2 }
export const metricCycles = {
	NONE: 'none',
	DAILY: 'daily',
	WEEKLY: 'weekly',
	MONTHLY: 'monthly',
	YEARLY: 'yearly',
}
export const mobileWindowWidthThreshold = 740
export const navVisibilityStates = { HIDDEN: 0, EMPTY: 1, VISIBLE: 2 }
export const positionEnum = {
	TOP: 'position-top',
	RIGHT: 'position-right',
	BOTTOM: 'position-bottom',
	LEFT: 'position-left',
}
export const teamMemberCountThreshold = 3
export const authMedium = 'account'
export const dateRolloverHour = 4 // Hour of the day to use instead of midnight for the user's perspective of changing from one day to the next. Used to avoid confusion with, for example, saying "Good morning" at 2am when someone is actually just up past midnight.
export const minimumPasswordLength = 6

export const onboardingSteps = {
	REJECT_INVITE: '-1',
	WELCOME: '0',
	SET_NAME: '1',
	MANAGE_ACCOUNT: '2',
	SET_PASSWORD: '3',
	BILLING: '4',
	BRANDING: '5',
	INVITE: '6',
	// CONTENT: '7',
	// CONTENT steps last active at Git commit: e35d6a2
	FINISHED: '8',

	// manage account sub-steps
	ACCOUNT_EXISTS: '2.0',
}

export const upgradeSteps = {
	EMAIL: '0',
	CHOOSE_PLAN: '1',
	CARD_INFO: '2',
}

// Commonly used pieces of copy.
export const copy = {
	LOADING: 'Loading…', // Use for general data-related actions
	LOG_IN: 'Log in',
	LOGGING_IN: 'Logging in…',
	ONETIME_LOGIN_BUTTON_LABEL_INITIAL: 'Send recovery link',
	ONETIME_LOGIN_BUTTON_LABEL_SUCCESS: 'Sent!',
	ONETIME_LOGIN_STARTED: 'Sent! Check your email and click the link to log in.',
	PROCESSING: 'Processing…', // Use only for payment processing
	RESEND: 'Re-send',
	RESEND_LINK: 'Re-send Link',
	RESET_PASSWORD_BUTTON_LABEL_INITIAL: 'Set password',
	RESET_PASSWORD_BUTTON_LABEL_SUCCESS: 'Change succeeded',
	RESET_PASSWORD_STARTED: 'Check your email to confirm your password change. This may take a few moments to arrive.',
	SENDING: 'Sending…',
	LEARN_MORE: 'Learn more',
	SETTING: 'Setting…',
	GO_TO_DASHBOARD: 'Go to Dashboard',
}

// Error messages used on forms throughout the account site.
// Start with a capital letter, end with a period, use sentence case, and use consistent language.
export const errorMessages = {
	BILLING_COUNTRY_BLANK: 'Please enter the country of the billing address.',
	BILLING_NAME_BLANK: 'Please enter the name that appears on the credit card.',
	BILLING_SUBSCRIPTION:
		'There was an error processing your subscription request. Please check your name, card number, expiry date, and security code and try again.',
	COMMUNITY_NOT_FOUND: 'Community not found!',
	EMAIL_ALREADY_REGISTERED: 'This email is already registered. Please try a different one.',
	EMAIL_ALREADY_ON_TEAM: 'This email is already in use by another team member.',
	DUPLICATE_EMAIL: 'Duplicate email detected.',
	EMAIL_BLANK: 'Please enter an email.',
	EMAIL_READ_ONLY:
		'Emails are unable to be edited. If you wish to change a team member email, please create a new user with the desired email address.',
	EMAIL_INVALID: 'Please enter a valid email.',
	EMAIL_OR_PASSWORD_INCORRECT: 'Your email or password is incorrect.',
	EXTENSION_LAUNCH_FAIL: 'Something went wrong when launching your team dashboard. Please try again.',
	EXTENSION_LOGIN_FAIL: 'Error logging into extension as the current user.',
	FEEDBACK_EMPTY: 'Please provide some feedback.',
	NAME_BLANK: 'Please enter a name.',
	NAME_BLANK_2: 'Please let us know how to address you.',
	NOTIFICATION_SEND_FAIL:
		'Error sending this notification to your team. Please try again, or contact help@momentumdash.com.',
	MAX_INPUT_LENGTH_EXCEEDED: 'This entry is too long. Please try something shorter.',
	PASSWORD_BLANK: 'Please enter a password.',
	PASSWORD_NEW_BLANK: 'Please enter a new password.',
	PASSWORD_VERIFY_BLANK: 'Please verify your password by entering it a second time.',
	PASSWORD_MISMATCH: 'Please check that your passwords match.',
	PASSWORD_WRONG: 'The password you entered did not match our records',
	PASSWORD_TOO_SHORT: 'Please choose a password that is at least 6 characters long to protect your account.',
	PAYMENT_ADD_CARD_ERROR:
		'Something went wrong when adding your card information. Please try again, or contact help@momentumdash.com.',
	PAYMENT_3DS_ERROR: 'There was an error validating your card. Please try again.',
	CONFIRMATION_3DS_ERROR:
		'There was an error confirming your card. Try adding a different payment method below or contact help@momentumdash.com.',
	SERVER_ERROR_GENERAL: 'Something went wrong. Please try again, or contact help@momentumdash.com.',
	SERVER_ERROR_NETWORK: 'There was a network error. Please check your connection and try again.',
	SERVER_ERROR_SEND_EMAIL_LINK:
		'We were unable to send this email. Please check that your email is valid and try again.',
	SUBSCRIPTION_CANCELING_ERROR: 'There was an error canceling your subscription.',
	SUBSCRIPTION_AUTORENEW_ERROR:
		'Something went wrong when attempting to turn off auto-renew. Please contact help@momentumdash.com and we will sort this out for you.',
	SSO_UNABLE_TO_REQUEST_TOKEN: 'There was an error logging you into the service.',
	OAUTH_ERROR: 'There was an error logging you into the service.',
	TEAM_NAME_BLANK: 'Please enter your team name.',
	TOO_MANY_LOGIN_ATTEMPTS:
		'Your account has been locked due to too many incorrect password attempts. Please try again in 5 minutes.',
	PARTNER_MISMATCH:
		'This email is already linked to a Momentum account. Please use a different email to create a Hit Quota account.',
}

// Error message copy used for onboarding error states. Different structure than standard form error messages.
export const onboardingErrors = {
	TOKEN_EXPIRED: {
		title: 'Oops! Your invitation has expired.',
		buttonLabel: 'Re-send invite',
	},
	ACTIVATION_ERROR: {
		title: 'Oops! We weren’t able to verify your account.',
		description: 'Please check your URL or log in to your existing Team account.',
		anchor: {
			text: 'Log in',
			path: '/',
		},
	},
	ALREADY_CONFIRMED: {
		title: 'This account has already been confirmed.',
		description: 'To continue, log in to your existing Team account.',
		anchor: {
			text: 'Log in',
			path: '/',
		},
	},
	NOT_FOUND: {
		title: 'Oops! We weren’t able to find that page.',
		description: 'Please check your URL and try again.',
		anchor: {
			text: 'Home',
			path: '/',
		},
	},
}

export const genericErrors = {
	EXTENSION_LAUNCH_FAIL: {
		title: 'Oops! We weren’t able to open your dashboard.',
		description: 'Please ensure the extension is installed and enabled, then try again.',
	},
	VERIFICATION_FAILED: {
		title: 'Oops! Verification Failed.',
		description: 'The link you clicked has expired or is no longer valid.',
	},
}

export const genericSuccess = {
	PASSWORD_RESET_SUCCESS: {
		title: 'Password Reset Succeeded',
		description: 'Your password has been reset. Next time you log in, use your new password.',
	},
	ACCOUNT_CREATED: {
		title: 'Account Created',
		description: 'Open a New Tab and click ⚙️ Settings in the bottom left to log in.',
	},
}

export const teamExpiredCases = {
	TRIAL_EXPIRED: {
		title: 'Your Team trial has expired.',
		message: 'Please add your payment details to continue using Team.',
		buttonText: 'Subscribe now',
		shortButtonText: 'Subscribe',
	},
	PAST_DUE: {
		title: 'We were unable to charge the card you have on file.',
		message: 'Please update your payment details to continue using Team.',
		buttonText: 'Update Payment',
	},
	SUBSCRIPTION_EXPIRED: {
		title: 'Your Team subscription has expired.',
		modalHeader: 'Renew Team Subscription',
		message: 'Renew your subscription to continue using Team.',
		buttonText: 'Renew Subscription',
		shortButtonText: 'Renew',
	},
	ACTION_REQUIRED: {
		title: 'Action is required on your account to confirm your card',
		message: 'Complete 3D Secure card confirmation to continue using Teams.',
		buttonText: 'Complete Confirmation',
	},
}

export const personalExpiredCases = {
	TRIAL_EXPIRED: {
		title: 'Your Plus trial has expired.',
		modalHeader: 'Upgrade to Plus',
		message: 'Upgrade to continue using Plus.',
		shortButtonText: 'Upgrade',
	},
	ACTION_REQUIRED: {
		title: 'Action is required on your account to confirm your card',
		message: 'Complete 3D Secure card confirmation to continue using Plus.',
		buttonText: 'Complete Confirmation',
	},
	PAST_DUE: {
		title: 'We were unable to charge the card you have on file.',
		message: 'Please update your payment details to continue using Plus.',
		buttonText: 'Update Payment',
	},
	SUBSCRIPTION_EXPIRED: {
		title: 'Your Plus subscription has expired.',
		modalHeader: 'Renew Plus Subscription',
		message: 'Renew your subscription to continue using Plus.',
		buttonText: 'Renew Subscription',
		shortButtonText: 'Renew',
	},
}

export const partnerExpiredCases = {
	TRIAL_EXPIRED: {
		title: 'Your trial has expired.',
		modalHeader: 'Upgrade now',
		message: 'Upgrade to keep access to your dashboard.',
		shortButtonText: 'Upgrade',
	},
	ACTION_REQUIRED: {
		title: 'Action is required on your account to confirm your card',
		message: 'Complete 3D Secure card confirmation to keep access to your dashboard.',
		buttonText: 'Complete Confirmation',
	},
	PAST_DUE: {
		title: 'We were unable to charge the card you have on file.',
		message: 'Please update your payment details to keep access to your dashboard.',
		buttonText: 'Update Payment',
	},
	SUBSCRIPTION_EXPIRED: {
		title: 'Your subscription has expired.',
		modalHeader: 'Renew Subscription',
		message: 'Renew your subscription to keep access to your dashboard.',
		buttonText: 'Renew Subscription',
		shortButtonText: 'Renew',
	},
}

// Flash messages used throughout the account site for announcements.
// Start with a capital letter, end with a period, use sentence case, and use consistent language.
// Use this object format: { message, icon }. Icon is optional. If included, set to 'error' or 'success'.
export const flashMessages = {
	API_KEY_COPIED: { message: 'The API key has been copied to your clipboard.', icon: 'success' },
	FEEDBACK_SUBMITTED: { message: 'Your feedback has been submitted. Thanks!', icon: 'success' },
	WAIT_WHILE_INVITING_MEMBERS: {
		message: 'Please wait while your team members are invited. Thanks for your patience!',
	},
	LOGGED_OUT: { message: 'You have been logged out.', icon: 'success' },
	NEW_LOGO_UPLOADED: { message: 'Your new logo has been uploaded.', icon: 'success' },
	PROFILE_UPDATED: { message: 'Your profile has been updated.', icon: 'success' },
	SECRET_COPIED: { message: 'The secret has been copied to your clipboard.', icon: 'success' },
	SUBSCRIPTION_CANCELED: { message: 'Your subscription has been canceled.', icon: 'success' },
	SUBSCRIPTION_RESUMED: { message: 'Your subscription has been resumed.', icon: 'success' },
	TEAM_MEMBER_DELETED_SELF: {
		message: 'You have been deleted from this team. Logging out.',
		icon: 'error',
	},
	TEAM_MEMBER_REMOVED_OWN_ADMIN: {
		message: 'You have removed your own admin privileges.',
		icon: 'error',
	},
	METRIC_VALUE_UPDATED: { message: 'Metric value has been updated.', icon: 'success' },
	METRIC_VALUE_UPDATE_ERROR: {
		message: 'Something went wrong while updating this metric. Please try again later.',
		icon: 'error',
	},
	SUCCESSFUL_PASSWORD_SET: 'Password set successfully.',
}

export const browsers = {
	chrome: {
		name: 'Chrome',
		isPrimary: true, // used for displaying Chrome installation link as primary button styled call-to-action
		momoInstallLink: 'https://moda.sh/chrome-install',
		browserInstallLink:
			'https://www.google.ca/chrome/?brand=CHBD&gclid=EAIaIQobChMIwP-m46aE3wIV7x-tBh0RFg_fEAAYASAAEgKYkPD_BwE&gclsrc=aw.ds',
	},
	firefox: {
		name: 'Firefox',
		momoInstallLink: 'https://moda.sh/firefox-install',
		browserInstallLink: 'https://www.mozilla.org/en-US/firefox/new/',
	},
	edge: {
		name: 'Edge',
		momoInstallLink: 'https://microsoftedge.microsoft.com/addons/detail/jdoanlopeanabgejgmdncljhkdplcfed',
		browserInstallLink: 'https://www.microsoft.com/en-us/edge',
	},
	safari: {
		name: 'Safari',
		setupLink: '/safari-setup',
		momoInstallLink: 'http://moda.sh/safari',
		browserInstallLink: 'https://support.apple.com/downloads/safari',
	},
}

// Copy used for tooltips throughout the site. End each tooltip with a period.
export const tooltips = {
	BILLING_NEXT_RENEWAL_ESTIMATE:
		'Your next renewal will include all users who have joined your team as of the last day of the current billing period.',
	BILLING_USER_COUNT_CALCULATION: 'The number of users is calculated each day at midnight Pacific time.',
}

// Warning message copy, used outside of form error messages and flash messages.
export const warningMessages = {
	CANNOT_DELETE_ONLY_ADMIN: 'You cannot delete the only admin of your team.',
	DELETE_YOURSELF: 'You are about to delete yourself! You cannot undo this.',
}

export const teamRoles = {
	// HACK: for now, role ids are static, this should be updated to check a property on the role object like isDefaultRole: true so that we can filter out the member role
	ADMIN: 'e8a0e04a-34f1-4f47-9473-41c9830d7886',
	MEMBER: '6014caaa-7bb7-4245-a867-1eebc900e5dd',
}

export const datetime = {
	MS_PER_DAY: 86400000, // Milliseconds per day (24 * 60 * 60 * 1000)
}

export const personalPlans = {
	PLUS_YEARLY: {
		id: 'plus-yearly-6',
		interval: 'year',
		frequency: 'Yearly',
		name: 'Momentum Plus - Yearly',
		type: 'Momentum Plus',
		price: 39.95,
		monthlyPrice: 3.33,
		badgeText: 'Save 33%!',
		default: true,
	},
	PLUS_MONTHLY: {
		id: 'plus-monthly-2',
		interval: 'month',
		frequency: 'Monthly',
		name: 'Momentum Plus - Monthly',
		type: 'Momentum Plus',
		price: 4.95,
	},
}

export const teamPlans = {
	TEAM_YEARLY: {
		id: 'team-yearly-1',
		interval: 'year',
		frequency: 'Yearly',
		name: 'Momentum Team - Yearly',
		type: 'Momentum Team',
		price: '59.40',
		monthlyPrice: 4.95,
		badgeText: 'Save 30%!',
		default: true,
	},
	TEAM_MONTHLY: {
		id: 'team-monthly-1',
		interval: 'month',
		frequency: 'Monthly',
		name: 'Momentum Team - Monthly',
		type: 'Momentum Team',
		price: 6.95,
	},
}

export const teamSalesPlans = {
	TEAM_YEARLY: {
		id: 'team-yearly-early',
		interval: 'year',
		frequency: 'Yearly',
		name: 'Momentum Team - Yearly',
		type: 'Momentum Team',
		price: '36.00',
		monthlyPrice: '3.00',
		badgeText: 'Save 40%!',
		default: true,
	},
	TEAM_MONTHLY: {
		id: 'team-monthly-early',
		interval: 'month',
		frequency: 'Monthly',
		name: 'Momentum Team - Monthly',
		type: 'Momentum Team',
		price: '5.00',
	},
}

export const promoPlan = {
	PLUS_YEARLY_PROMO: {
		id: 'plus-yearly-resubscribe-2020',
		interval: 'year',
		frequency: 'Yearly',
		name: 'Momentum Plus - Yearly',
		type: 'Momentum Plus',
		price: 19.95,
		badgeText: 'Save 50%!',
		overrideName: 'Loyalty Plan',
	},
}

export const salesPreview = {
	// DO NOT REMOVE, this is used for dev panel show sales preview
	// update this with the sales info, and then go to dev panel to preview sales
	yearly: {
		id: 'plus-yearly-black-friday-2020',
		interval: 'year',
		frequency: 'Yearly',
		name: 'Momentum Plus - Yearly',
		type: 'Momentum Plus',
		priceInCents: 2995,
		friendlyPrice: '29.95',
		friendlyMonthlyPrice: '2.50',
		endDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
		percentOff: 25,
		title: 'Black Friday Sale',
		badgeText: '25% Off',
		class: 'black-friday',
	},
}

export const bannerEnum = {
	TEAM_TRIAL: 'team-trial',
	EXPIRED_TEAM: 'expired-team',
	EXPIRING_TEAM: 'expiring-team',
	PLUS_SALE: 'plus-sale',
	PLUS_TRIAL: 'plus-trial',
	EXPIRING_PLUS: 'expiring-plus',
	EXPIRED_PLUS: 'expired-plus',
	ACTION_REQUIRED: 'action-required',
}

export const experiments = {
	resubscribeFlow: {
		liveXid: 'lwi_664WQ0a4Hu0XrBmQFw',
		devXid: 'DVD0vzTBQcGxcd5Ow-NHfw',
		variants: [
			{ id: 'UPGRADE_LEGACY', component: 'UpgradeLegacy', class: 'upgrade-legacy' }, // 1 page, variant 0, no longer used, should remove once the experiment ends
			{ id: 'UPGRADE', component: 'Upgrade', class: 'upgrade' }, // 3-step. variant 1
		],
	},
}

export const supportedCardTypes = [
	'visa',
	'americanExpress',
	'mastercard',
	/* check if maestro and discover are in fact supported */ 'maestro',
	'discover',
]

export const urls = {
	PLUS_OVERVIEW: 'https://get.momentumdash.help/hc/en-us/articles/115015748548-Momentum-Plus-Overview',
	CONTACT: 'https://momentumdash.com/contact',
	HELP: 'https://get.momentumdash.help/hc/en-us',
}

export const partners = {
	1: {
		name: 'Hit Quota',
		logoUrl: 'partners/hit-quota-logo-white-full.svg',
		originPWA: process.env.VUE_APP_HIT_QUOTA_PWA_URL,
		plans: {
			plus: 'Hit Quota',
			team: 'Hit Quota Team',
		},
	},
}
