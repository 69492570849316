<template>
	<div class="metric-integrations">
		<smooth-height :nested="true" :hide-overflow="true">
			<div v-if="!providers.loaded" key="1">
				<route-loader />
			</div>
			<div v-else-if="!selectedProvider" key="2">
				<ul class="providers">
					<li v-for="(provider, index) in providers.items" :key="index" class="modal-tile provider" @click="selectProvider(provider)">
						<img :src="provider.smallIconUrl" class="provider-icon">
						<div>{{ provider.title }}</div>
					</li>
				</ul>
				<a href="https://suggestions.momentumdash.help/" target="_blank" class="suggest">Suggest integration →</a>
			</div>
			<div v-else-if="!selectedProvider.connected" key="3" class="connect">
				<h3>Connect to {{ selectedProvider.title }}</h3>
				<p class="description">A secure window will open. Momentum doesn't see or access your login info.</p>
				<button class="button button-connect" @click="connectProvider">Connect</button>
			</div>
			<div v-else-if="selectedProvider.connected" key="4">
				<select v-model="selectedDataPointId" class="data-points">
					<option v-if="selectedProviderDataPointOptions.length" disabled value="">Select an option</option>
					<option v-for="(option, index) in selectedProviderDataPointOptions" :key="index" :value="option.value">{{ option.label }}</option>
				</select>
			</div>
		</smooth-height>
	</div>
</template>



<script>
import SmoothHeight from '@/components/common/SmoothHeight'
import RouteLoader from '@/components/loaders/LoadingRoutes'
import teamMetricDataService from '@/services/teamMetricDataService'
import teamMetricManager from '../../../managers/teamMetricManager'
export default {
	name: 'MetricIntegration',
	components: { RouteLoader, SmoothHeight },
	props: {
		manager: { type: Object, required: true },
		editProps: { type: Object, required: true },
		selectedItem: { type: Object, required: true },
	},
	store: ['teamInfo'],
	computed: {
		providers() {
			return teamMetricManager.providers
		},
		selectedProviderId: {
			get() {
				return this.editProps.providerId
			},
			set(value) {
				this.$set(this.editProps, 'providerId', value)
			},
		},
		selectedProvider() {
			return this.providers.items[this.selectedProviderId]
		},
		selectedDataPointId: {
			get() {
				return this.editProps.providerDataPointId
			},
			set(value) {
				this.$set(this.editProps, 'providerDataPointId', value)
			},
		},
		selectedDataPoint() {
			return this.selectedProvider.dataPoints.find(dataPoint => {
				return dataPoint.id === this.selectedDataPointId
			})
		},
		selectedProviderDataPointOptions() {
			if (!this.selectedProvider.dataPoints) return []
			return this.selectedProvider.dataPoints.map(dataPoint => ({
				value: dataPoint.id,
				label: dataPoint.name,
			}))
		},
	},
	watch: {
		selectedProviderId(id) {
			if (id || id === 0) this.$set(this.editProps, 'providerId', id)
		},
		selectedDataPointId(newId, oldId) {
			if (newId || newId === 0) {
				this.$set(this.editProps, 'providerDataPointId', newId)
				let oldDataPoint // if the label has been added or edited by the user, do not overwrite, otherwise update it
				if (oldId || oldId === 0) {
					oldDataPoint = this.selectedProvider.dataPoints.find(dataPoint => dataPoint.id === oldId)
				}
				if (!this.editProps.label || this.editProps.label === (oldDataPoint && oldDataPoint.name)) {
					this.$set(this.editProps, 'label', this.selectedDataPoint.name)
				}
			}
		},
		'providers.loaded': {
			handler(loaded) {
				if (loaded) {
					const props = this.editProps
					if (props.providerId) {
						this.selectedProviderId = props.providerId
						this.fetchDataPoints(this.selectedProvider)
					}
					if (props.providerDataPointId) this.selectedDataPointId = props.providerDataPointId
				}
			},
			immediate: true,
		},
	},
	methods: {
		selectProvider(provider) {
			this.selectedProviderId = provider.id
			if (this.selectedProvider.connected) this.fetchDataPoints(provider)
		},
		connectProvider() {
			teamMetricDataService
				.connectProvider(this.selectedProvider.id)
				.then(response => {
					const data = response.data
					if (data.status === 'authRequired') {
						this.launchConnectWindow(
							data.authorizationUrl,
							data.winHeight,
							data.winWidth,
							data.windowFeatures
						)
					}
				})
				.catch()
		},
		launchConnectWindow(url, height = 510, width = 600, windowFeatures = 'titlebar, resizable, status') {
			const leftPos = window.screen.width / 2 - width / 2,
				topPos = window.screen.height / 2 - height / 2,
				authWindow = window.open(
					url,
					'MomentumAuthWindow',
					`${windowFeatures}, left=${leftPos}, top=${topPos}, width=${width}, height=${height}`
				)

			let interval = setInterval(() => {
				if (authWindow.closed) {
					clearInterval(interval)
					this.onAuthWindowClose()
				}
			}, 1000)
		},
		onAuthWindowClose() {
			teamMetricManager.refreshMetricProviders().then(() => this.fetchDataPoints(this.selectedProvider))
		},
		fetchDataPoints(provider) {
			if (!provider) provider = this.selectedProvider
			if (!provider) return console.warn('Integration used does not exist')
			teamMetricDataService
				.getProviderDataPoints(provider.id)
				.then(response => {
					let dataPoints = response && response.data && response.data.dataPoints
					if (dataPoints) {
						dataPoints.sort((a, b) => {
							if (!a.name || !b.name) return 0
							const nameA = a.name.toUpperCase()
							const nameB = b.name.toUpperCase()
							if (nameA < nameB) return -1
							if (nameA > nameB) return 1
							return 0
						})
						this.$set(provider, 'dataPoints', dataPoints)
					}
				})
				.catch(error => console.error(error))
		},
	},
}
</script>



<style scoped>
	.metric-integrations { margin-bottom: var(--form-element-margin); position: relative; }
		.section-loading { margin: 0; padding: 3rem 0; }
		.providers { margin: 0; padding: var(--form-element-margin) 0; display: grid; grid-template-columns: repeat(auto-fit, 150px); grid-gap: 1rem; }
			.provider { }
				.provider-icon { height: 70px; margin-bottom: 1rem; display: block; }

		.suggest { display: inline-block; }

		.connect { padding: 20px; background-color: var(--body-background-color); border-radius: var(--border-radius); }
			.button-connect { }

		.data-points { margin: 0; }
</style>
