<template>
	<div>
		<form id="payment-form" class="payment-form" data-test="payment-form" @submit.prevent>
			<div class="form-row u--no-margin-bottom">
				<div class="label-row">
					<label>{{ $t('upgrade.CARD_DETAILS') }}</label>
				</div>
				<!-- Dont put reactive properties on card-element, the re-render will cause a loss of stripe's special classes -->
				<div id="card-element" class="input" data-test="stripe-elements" inline-modal-focus></div>
			</div>

			<div class="form-row form-bottom-row u--no-margin-bottom">
				<span class="form-row-group name-on-card">
					<input
						ref="name"
						v-model.trim="name"
						:class="{ 'error': hasNameError }"
						name="cardholder-name"
						class="input form-field is-empty u--no-margin-bottom"
						required
						:placeholder="$t('upgrade.NAME_ON_CARD')"
						data-test="name"
					>
				</span>
				<span class="form-row-group country">
					<Countries v-model="country" :error="hasCountryError"></Countries>
				</span>
			</div>

		</form>

		<!-- listen to verify event emitted by the recaptcha component -->
		<recaptcha v-if="recaptcha" ref="recaptcha" @verify="onRecaptchaResponse" @end-submit-process="endSubmitProcess"></recaptcha>
	</div>
</template>


<script>
import Countries from '../common/Countries'
import { errorMessages } from '@/constants'
import eventBus from '@/eventBus'
import Vue from 'vue'
import Recaptcha from './Recaptcha'

export default {
	name: 'PaymentForm',
	components: { Countries, Recaptcha },
	props: {
		onToken: { type: Function, default() {} },
		recaptcha: { type: Boolean, default: false },
		stripeFontSize: { type: String, default: '' },
	},
	store: ['userInfo', 'authInfo'],
	data() {
		return {
			name: '',
			country: 'United States',
			method: '',
			hasNameError: false,
			hasCardError: false,
			hasCountryError: false,
			stripeMounted: false,
		}
	},
	computed: {
		nameIsEmpty() {
			return !this.name || this.name.length === 0
		},
		countryIsEmpty() {
			return !this.country || this.country.length === 0
		},
		recaptchaTestKey() {
			return process.env.VUE_APP_RECAPTCHA_TEST_KEY
		},
	},
	created() {
		eventBus.$on('submit-card-info', this.submitNewCard)
		eventBus.$on('focusCardInput', this.focusCardInput)
		eventBus.$on('modalClosed', this.clearErrors)
		eventBus.$on('modalClosed', this.clearInputs)
	},
	destroyed() {
		eventBus.$off('submit-card-info', this.submitNewCard)
		eventBus.$off('focusCardInput', this.focusCardInput)
		eventBus.$off('modalClosed', this.clearErrors)
		eventBus.$off('modalClosed', this.clearInputs)
	},
	mounted() {
		if (Vue.prototype.$stripe) {
			this.$nextTick(() => {
				this.populateForm()
			})
		} else {
			this.setupStripe()
		}
	},
	methods: {
		doRecaptcha() {
			this.$refs.recaptcha.execute()
		},
		onRecaptchaResponse(recaptchaResult) {
			if (!recaptchaResult && !this.recaptchaTestKey) {
				this.$emit('set-error', 'Recaptcha failed')
				return
			}
			this.onToken(this.stripeToken, this.name, this.recaptchaTestKey || this.$refs.recaptcha.result)
		},
		setupStripe() {
			if (!Stripe) {
				setTimeout(() => {
					this.setupStripe()
				}, 1000)
			} else {
				if (!Vue.prototype.$stripe) Vue.prototype.$stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)
				this.$nextTick(() => {
					this.populateForm()
				})
			}
		},
		populateForm() {
			let elements = this.$stripe.elements()
			const style = {
				base: {
					fontSize: this.stripeFontSize || '16px',
					lineHeight: '24px',
				},
			}
			this.card = elements.create('card', { style })
			if (document.getElementById('card-element')) {
				this.card.mount('#card-element')
				this.card.on('ready', () => (this.stripeMounted = true))
			} else if (!this._isBeingDestroyed && !this._isDestroyed) {
				console.error('Card element not found')
			}
		},
		clearErrors() {
			this.hasNameError = this.hasCardError = this.hasCountryError = false
		},
		clearInputs() {
			if (this.card) this.card.clear()
			this.name = ''
		},
		focusCardInput() {
			this.card && this.card.focus()
		},
		focusNameInput() {
			this.$refs.name.focus()
		},
		awaitStripeMounted() {
			return new Promise(resolve => {
				if (this.stripeMounted) return resolve()
				const unwatch = this.$watch('stripeMounted', mounted => {
					if (mounted) {
						unwatch()
						resolve()
					}
				})
			})
		},
		async submitNewCard() {
			await this.awaitStripeMounted()
			this.$emit('clear-error')
			this.clearErrors()
			let extraDetails = { name: this.name, address_country: this.country }
			try {
				const result = await this.$stripe.createToken(this.card, extraDetails)
				if (result.error) {
					this.hasCardError = true
					this.$emit('set-error', result.error.message)
					this.endSubmitProcess()
					return
				}
				if (this.nameIsEmpty) {
					this.hasNameError = true
					this.$emit('set-error', errorMessages.BILLING_NAME_BLANK)
					this.endSubmitProcess()
					this.focusNameInput()
					return
				}
				if (this.countryIsEmpty) {
					this.hasCountryError = true
					this.$emit('set-error', errorMessages.BILLING_COUNTRY_BLANK)
					this.endSubmitProcess()
					return
				}
				if (this.recaptcha && !this.recaptchaTestKey) {
					this.stripeToken = result
					this.doRecaptcha()
				} else {
					this.onToken(
						result,
						this.name,
						this.recaptcha ? this.recaptchaTestKey || this.$refs.recaptcha.result : undefined
					)
				}
			} catch (error) {
				console.error(error)
			}
		},
		endSubmitProcess() {
			this.$emit('end-submit-process')
		},
		async confirmCardPayment(clientSecret) {
			await this.awaitStripeMounted()
			try {
				let result
				const paymentMethod = {
					card: this.card,
					billing_details: { name: this.name },
				}
				if (clientSecret.startsWith('pi_')) {
					result = await this.$stripe.confirmCardPayment(clientSecret, {
						payment_method: paymentMethod,
						setup_future_usage: 'off_session',
					})
					if (result.error) console.error(result.error)
					return [result.paymentIntent?.status || 'failed', result.error?.message]
				} else {
					result = await this.$stripe.confirmCardSetup(clientSecret, {
						payment_method: paymentMethod,
					})
					if (result.error) console.error(result.error)
					return [result.setupIntent?.status || 'failed', result.error?.message]
				}
			} catch (e) {
				console.error(e)
				this.$emit('set-error', e)
				return 'failed'
			}
		},
	},
}
</script>


<style scoped>
	.input:focus, .StripeElement--focus { background-color: var(--input-bg-color-focus); box-shadow: inset 0 0 0 2px var(--input-border-color-focus); }

</style>
