<template>
	<div class="o-auth-button">
		<button class="dash-button" type="button" :data-test="'sso-login-' + type" @click="ssoLogin">
			<!-- this doesnt work dynamically -->
			<img v-if="type === 'google'" class="icon" src="@/img/icons/sso/google.svg" />
			<img v-if="type === 'apple'" class="icon" src="@/img/icons/sso/apple.svg" />
			<span>{{ buttonDefinitions[type].string }}</span>
		</button>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { initiateSso } from '../../services/sso/ssoHelpers'

export default defineComponent({
	name: 'OAuthButton',
	props: {
		type: { type: String, required: true },
	},
	setup() {
		return {
			buttonDefinitions: {
				apple: {
					string: 'Continue with Apple',
					icon: '@/img/icons/sso/apple.svg',
				},
				google: {
					string: 'Continue with Google',
					icon: '@/img/icons/sso/google.svg',
				},
			},
		}
	},
	methods: {
		async ssoLogin() {
			const params = await initiateSso(this.type)
			if (params) location.href = '/onetime?auto=true&' + new URLSearchParams(params).toString()
		},
	},
})
</script>

<style scoped>
.o-auth-button .dash-button {
	--v-padding: 11px;
	--h-padding: 34px;
	--icon-size: 17px;
	padding: var(--v-padding) var(--h-padding) calc(var(--v-padding) + 1px);
	display: flex; align-items: center; justify-content: center;
	border: 2px solid rgba(255, 255, 255, 0.85);
	border-radius: 50px;
	font-weight: 500;
	outline: none;
	transition: background-color 0.1s ease;
	border-color: transparent;
	background: rgba(255 255 255 / 0.85);
	box-shadow: 0 3px 12px rgba(0 0 0 / 0.3);
	color: black;
	font-size: 1rem;
	cursor: pointer;
	-webkit-backdrop-filter: blur(2rem);
	backdrop-filter: blur(2rem);
	gap: 0;
}

.o-auth-button .dash-button .icon {
	width: var(--icon-size);
	height: var(--icon-size);
	translate: -10px;
	object-fit: contain;
}

.o-auth-button .dash-button:hover {
	opacity: 1;
	background: white;
}
</style>
