import Axios from 'axios'
import util from './util'
import store from '@/store'

const axios = Axios.create({
	baseURL: process.env.VUE_APP_BASE_API_URI,
	timeout: 100000,
})

axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		if (!config) config = {}
		config.headers = {
			...config.headers,
			...(store.userInfo.partnerId ? { 'X-Momentum-PartnerId': store.userInfo.partnerId } : {}),
		}
		const whiteListed = urlIsWhiteListed(config.url, config.baseURL),
			greyListed = urlIsGreyListed(config.url, config.baseURL)
		if (!whiteListed) {
			config.withCredentials = false
			let token = window.localStorage.getItem('token')
			if (token) {
				config.headers = {
					Authorization: 'Bearer ' + token,
					'X-Momentum-Clientdate': util.activeLocalDateTimeStringForDate(new Date()),
				}
			} else if (!greyListed) {
				if (util.isDev()) {
					console.log('WARNING - Url is not anonymous and you are not logged in - whitelist this if needed.')
				}
				return {
					...config,
					cancelToken: new Axios.CancelToken(cancel => cancel('Cancel unauthorized request')),
				}
			}
		}
		return config
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error)
	}
)

export default axios

function urlIsGreyListed(url, baseUrl) {
	// Grey listed urls are allowed to be requested without a token, but a token will be sent if it exists
	// TODO: DRY these up
	if (url.startsWith(baseUrl) || (!url.startsWith('https://') && !url.startsWith('http://'))) {
		const greyList = ['team/onboarding', 'account/subscriptions', 'user/sso-state-uuid']
		if (url.startsWith(baseUrl)) url = url.replace(baseUrl, '')
		if (url.startsWith('/')) url = url.slice(1)
		return greyList.some(greyListedUrl => {
			return url.startsWith(greyListedUrl)
		})
	}
	return true
}

function urlIsWhiteListed(url, baseUrl) {
	// SECURITY NOTE: we need to ensure that we whitelist all non-momentum sites, so we don't send our token to other services
	// TODO: Unit test the shit out of this!!
	if (url.startsWith(baseUrl) || (!url.startsWith('https://') && !url.startsWith('http://'))) {
		const whiteList = [
			'user/authenticate',
			'user/register',
			'user-search',
			'login/sso',
			'user/verify-invite',
			'user/accept-invite',
			'user/resend-invite',
			'user/forgot',
			'user/password',
			'login/onetime/send',
			'login/onetime/redeem',
			'account/plans',
			'coupons',
			'team/basicInfo',
		]
		if (url.startsWith(baseUrl)) url = url.replace(baseUrl, '')
		if (url.startsWith('/')) url = url.slice(1)
		return whiteList.some(whiteListedUrl => {
			return url.startsWith(whiteListedUrl)
		})
	}
	return true
}
